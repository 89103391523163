import React from "react";
import Footer from "./inc/Footer";
import Form from "./inc/Form";

function Contact() {
  return (
    <div>
      <div class="background">
        <div class="container text-center">
          <div class="row">
            <h1 class="contact-heading">Contact Us</h1>
            <div class="contact-container col-md-6" id="contact-container">
              <Form />
            </div>
            <div class="col">
              <ul class="list-group mb-3">
                <li class="list-group-item d-flex justify-content-between lh-sm">
                  <div>
                    <h6 class="my-0">
                      <i class="fa fa-phone"></i> Telephone
                    </h6>
                  </div>
                  <span>
                    <a href="tel:+251116180907">+251116180907</a>
                  </span>
                </li>
                <li class="list-group-item d-flex justify-content-between lh-sm">
                  <div>
                    <h6 class="my-0">
                      <i class="fa fa-phone"></i> Mobile
                    </h6>
                  </div>
                  <span>
                    <a href="tel:+251911203937">+251911203937</a>
                  </span>
                </li>
                <li class="list-group-item d-flex justify-content-between lh-sm">
                  <div>
                    <h6 class="my-0">
                      <i class="fa fa-fax"></i> FAX
                    </h6>
                  </div>
                  <span>
                    <a href="tel:+251116180892">+251116180892</a>
                  </span>
                </li>
                <li class="list-group-item d-flex justify-content-between lh-sm">
                  <div>
                    <h6 class="my-0">
                      <i class="fa fa-envelope"></i> Email
                    </h6>
                  </div>
                  <span>
                    <a href="mailto:fantu.golasiyoom@gmail.com">
                      fantu.golasiyoom@gmail.com
                    </a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
