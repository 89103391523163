import React from "react";
import Footer from "./inc/Footer";

function Risk() {
  return (
    <div>
      <div class="risk-header">
        <h1>Risk Managemnet</h1>
      </div>
      <article class="blog-post">
        <h5 class="blog-post-title mb-1">What is risk management?</h5>

        <p>
          Risk management is the process of identifying, assessing and
          controlling threats to an organization's capital and earnings. These
          threats, or risks, could stem from a wide variety of sources,
          including financial uncertainty, legal liabilities, strategic
          management errors, accidents and natural disasters.
        </p>

        <p>
          In the world of finance, risk management refers to the practice of
          identifying potential risks in advance, analysing them and taking
          precautionary steps to reduce/curb the risk.{" "}
        </p>

        <h5>How we can help</h5>
        <ul>
          <li>
            <b>Revenue Generation:</b> We offer a sustainable approach to
            enhancing revenue generation, collection and administration by
            supporting governments and revenue administrations to implement
            efficient systems and build people capacity and integrity. CA has
            historically primarily focused on customs reform which we are
            growing to include tax capability. Our service offer is Design and
            delivery of large scale change management programmes focused on
            technical assistance in people, policy, process and platforms to
            enhance revenue and support trade facilitation.
          </li>
          <li>
            <b>Trade Competitiveness:</b> We offer a practical approach to
            address key trade constraints, bringing together policy makers and
            the private sector, framed around the four pillars of international
            trade: improving access to trade finance; reducing transport
            constraints; improved understanding, use and enforcement of
            commercial terms; and reforming the regulatory environment for
            trade. Our service offerings are: Practical advice to enhance export
            competitiveness that bridges public/private sectors Management of
            complex global trade and prosperity programmes.
          </li>
        </ul>
        <p>
          Working in concert, the three pillars of economic growth— innovation,
          trade, and talent—supported by strong infrastructure and governance,
          will generate economic growth and opportunities for prosperity that
          benefits more people, more regions, and the state as a whole.
        </p>
      </article>
      <Footer />
    </div>
  );
}

export default Risk;
