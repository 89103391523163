import React from "react";
import Footer from "./inc/Footer";
import Fantu from "./Images/Mr.fantugola.jpg";
import { Link } from "react-router-dom";

function About() {
  return (
    <div>
      <div class="description">
        <h1>Profile of Herfazy Consult</h1>
        <article class="blog-post">
          <h2 class="blog-post-title mb-1">Who are we?</h2>

          <p>
            <b>Herfazy Consult</b> is a continuation of the Fan Trading &
            Consultancy Services (Fan Consult) which was established in 1993 by
            Ato Fantu Golla. It is certified as Management Consultant by the
            Ethiopian Management Institute and registered with the Ministry of
            Trade & the Ministry of Finance and Economic Development. Its
            headquarters is located at a prime area in Addis Ababa, Bole
            Sub-city, Woreda 06, Haile Gebreselassie Street, housed in Getahun
            Beshah Building.
          </p>

          <h3>What do we have to offer?</h3>
          <p>
            Herfazy Consult provides value-adding solutions through commitment
            based on the following core principles:
          </p>
          <ul>
            <li>
              Provide honest, objective, thoughtful, and experienced advice.
            </li>
            <li>
              Advice major strategic or operational need or an organizational
              challenge.
            </li>
            <li>
              Help people and companies explore extraordinary opportunities,
              manage and sustain growth, and maximize revenue.
            </li>
            <li>
              Take an overall, independent, and fact-based view of a client’s
              performance.
            </li>
            <li>
              Rely on facts because it provides clarity, credible
              recommendations and align people.{" "}
            </li>
            <li>
              Rely on multiple people, not a single consultant or a single
              office,
            </li>
            <li>Invest significant resources in building knowledge.</li>
            <li>Bring innovations in management practice to clients</li>
            <li>Build client capabilities to sustain improvement</li>
            <li>
              Develop practical solutions founded on solid data, robust
              analysis, creative thinking, and close collaboration with client
              teams.
            </li>
            <li>
              Work with our clients to ensure a seamless transition to
              implementing the results.
            </li>
            <li>
              Combine leading functional capabilities with deep industry
              expertise to help resolve a wide range of issues faced by our
              clients.{" "}
            </li>
            <li>
              Conduct feasibility studies, business plans and impact analysis
              covering technical, logistical, legal, environmental and financial
              considerations.
            </li>
          </ul>

          <h2>Our team</h2>
          <p>
            Herfazy Consult is managed by qualified professionals with wide
            ranging experience in their field of expertise. The firm has
            qualified permanent staff and short term contractual employees. In
            addition, the firm recruits high caliber professionals as the
            situation demands. To this effect, Herfazy Consult cooperates with
            several domestic and international consulting firms.
          </p>
          <p>
            Our team of consultants includes professionals in the fields of
            Tourism, Hotel, Catering, Trade, Management, Finance, Environment,
            Manpower planning, Marketing Management, International and Domestic
            Trade, Industrial Economics, Asset Evaluation, Legal advices,
            Project studies, Management Reviews and appraisals.{" "}
          </p>
          <h2>Some of our works</h2>
          <p>
            Due to its attachment to the tourism and hotel industry and vast
            experience in the sector, Herfazy Consult, trough Fan Trading &
            Consultancy Service, was chosen in the year 2000 to conduct a
            Tourism Potential Survey of the Southern Nations and Nationalities
            and Peoples Regional State. We identified 200 project ideas and
            developed 50 project profiles for private investors. Our company has
            successfully completed the study and obtained a certificate of
            successful completion.
          </p>

          <p>
            Based on the request of private investors, Herfazy Consult also
            completed several feasibility studies in different sectors. These
            feasibility studies are accepted by commercial banks and other
            government bodies. Some of the projects are completed and operating.
            In the recent years our company has been involved in preparing
            organizational structure, manning, job description and salary scale
            projects.
          </p>
          <p>
            Herfazy Consult has also carried out several projects in association
            with international consulting companies. Some of the European Union
            Projects completed with the association of these firms were:
            Evaluations on Food Security Assistance to Ethiopia; and Evaluation
            on European Union assistance to Ethiopia in the years 1996-2002.
            Herfazy Consultant has also participated in the preparation of the
            Ethiopian Investment Guide under close supervision of UNCTAD.
          </p>
          <p>
            Herfazy Consult, through its Originator, <b>Ato Fantu Golla</b>, has
            participated in a 20 months World Bank Funded Project (Ethiopian
            Cultural Heritage Project) and completed successfully by acquiring
            certificate of completion.{" "}
          </p>
          <section class="py-2">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <h4>Our Track Record</h4>
                  <div class="underline mb-3"></div>
                </div>
                <div class="col-lg-4">
                  <div class="card shadow">
                    <div class="card-body">
                      <h5>Feasibility study on the establishing of:</h5>
                      <div class="mb-2 sub-underline"></div>
                      <b>Three star hotel</b>
                      <li>Lal Hotel – 1995</li>
                      <li>Simien Lodge PLC - 2005</li>
                      <li>Bahardar Hotel - 2007</li>
                      <li>Red Jackal Hotel - 2008</li>
                      <li>Selfu Taye hotel - 2010</li>
                      <div class="mb-2 sub-underline"></div>
                      <b>Four star hotel</b>
                      <li>Genete PLC -1999</li>
                      <li>Desalegn & Family -1999</li>
                      <li>Adules Share Co – 2007</li>
                      <li>Taye Belay Hotel – 2007</li>
                      <li>Experience Ethiopia – 2011</li>
                      <div class="mb-2 sub-underline"></div>
                      <b>Five star hotel</b>
                      <li>Rotan International Hotel with ALSAM PLC – 2010</li>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="card shadow">
                    <div class="card-body">
                      <h5>Business Plan</h5>
                      <div class="mb-2 sub-underline"></div>
                      <b>Three Star</b>
                      <li>Tahir Mohamed – 2011</li>
                      <li>Gateway hotel - 2011</li>
                      <li>Negusse Kassa - 2012</li>
                      <li>Asfaw Gola - 2012 </li>
                      <li> Worku - 2013</li>
                      <div class="mb-2 sub-underline"></div>
                      <b>Four Star</b>
                      <li>Hamelmal Takele - 2010</li>
                      <li>Abdu Hotel - 2011</li>
                      <li>Keria Ahmed - 2011</li>
                      <li>Kedi Kemal - 2011</li>
                      <li>Demisse Karu - 2012</li>
                      <li>Sun Bird Hotel - 2012</li>
                      <li>Astede Haile Hotel - 2013</li>
                      <li>Getfam Hotel - 2013</li>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="card shadow">
                    <div class="card-body">
                      <h5>Preopening</h5>
                      <div class="mb-2 sub-underline"></div>
                      <b>Three Star</b>
                      <li>Hibret Share Co – 2008/9</li>
                      <li>Tegen Accommodation Centre – 2008/9</li>
                      <div class="mb-2 sub-underline"></div>

                      <b>Four Star</b>
                      <li>Dream Liner Hotel – 2008/9</li>
                      <li>Bole Ambassador Hotel - 2008</li>
                      <li>Yishamu Hotel – 2009/10</li>
                      <li>Taye Belay Hotel – 2009/11</li>
                      <li>Sabean Hotel - 2012/2013</li>
                      <li>Afrodawit Hotel - 2013</li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="py-2">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="underline mb-3"></div>
                </div>
                <div class="col-lg-4">
                  <div class="card shadow">
                    <div class="card-body">
                      <h5>
                        Organizational Structure, Manning Salary Scale,
                        Operational and Preparation of Manuals
                      </h5>
                      <div class="mb-2 sub-underline"></div>

                      <li>Safari Lodge in Nazareth - 2001</li>
                      <li>Papyrus Hotel - 2002</li>
                      <li>Taye Belay Hotel – 2007</li>
                      <li>Bole Ambassador Hotel -2008 </li>
                      <li>Sabean Hotel - 2012</li>
                      <li>Afrodawit hotel - 2012</li>
                      <li>Mount new hotel - 2012</li>
                      <li>Tegen Accommodation center – 2012</li>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="card shadow">
                    <div class="card-body">
                      <h5>International and domestic procurement advice</h5>
                      <div class="mb-2 sub-underline"></div>
                      <li>Taye Belay Hotel – 2009</li>
                      <li>Dreamliner Hotel - 2009</li>
                      <li>Ambassador Hotel – 2010</li>
                      <li>Sabean Hotel – 2011</li>
                      <li>Asfaw Gola – 2012</li>
                      <li>Sabean Hotel – 2012</li>
                      <li>Getatchew Kebede Hotel – 2013</li>
                      <li>Temesgen Hotel – 2013</li>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="card shadow">
                    <div class="card-body">
                      <h5>Salary Scale and Benefit structure survey</h5>
                      <div class="mb-2 sub-underline"></div>

                      <li>Hiton Hotel – 2001</li>
                      <li>Bole Ambassador Hotel – 2008</li>
                      <li>Ambassador Garment</li>
                      <li>ZTE International Company</li>
                      <li>X Pharmaceutical Company</li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="py-2">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="underline mb-3"></div>
                </div>
                <div class="col-lg-4">
                  <div class="card shadow">
                    <div class="card-body">
                      <h5>
                        Organizing symposium / Seminar/ continental and National
                        meetings
                      </h5>
                      <div class="mb-2 sub-underline"></div>
                      <b>Building a Vibrant City</b>
                      <li>AACC - 2001</li>
                      <div class="mb-2 sub-underline"></div>
                      <b>Poverty Reduction</b>
                      <li>AACC – 2001</li>
                      <div class="mb-2 sub-underline"></div>
                      <b>Several big events</b>
                      <li>OAU/AU international and continental events</li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <h2 class="founder-header">Founder</h2>
          <div class="col-md-12 fantu-card">
            <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
              <div class="col p-4 d-flex flex-column position-static">
                <strong class="d-inline-block mb-2 text-success">
                  Founder and managing director
                </strong>
                <h3 class="mb-0">Fantu Gola Siyoum</h3>
                <div class="mb-1 text-muted"></div>
                <p class="mb-auto">
                  Fantu Gola is the founder and managing director of Herfazy
                  consult, with rich and wide experience in many sectors and
                  industires. Fantu Gola was formerly the head of crown agencies
                  and now works as a consultant and property manager of his own
                  resort that resides in the state of Gondar.
                </p>
                <Link
                  to="/fantu"
                  onClick={() => window.reload()}
                  class="stretched-link"
                >
                  About Fantu Gola
                </Link>
              </div>
              <div class="col-auto d-none d-sm-block">
                <img
                  class="bd-placeholder-img"
                  src={Fantu}
                  width="250"
                  height="250"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-label="Placeholder: Thumbnail"
                  preserveAspectRatio="xMidYMid slice"
                  focusable="false"
                />
              </div>
            </div>
          </div>
        </article>
      </div>
      <Footer />
    </div>
  );
}

export default About;
